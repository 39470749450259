/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Srrange',
  data: function data() {
    return {
      week: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      dateList: [],
      thisDate: '',
      kong: 0,
      banci: [],
      tone: ['#969696', '#3399ff', '#ffd133', '#75c663', '#ccda46', '#42e6a4', '#D89cf6', '#916dd5', '#12cad6', '#3282bb', '#e3249', '#ff9f76', '#ff971d', '#f0cf85', '#617be3'],
      character: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'],
      toneIdx: 1,
      toneIdxs: 1,
      paibanitem: '',
      paibanitems: '',
      thisDates: '',
      dateidx: 0
    };
  },
  components: {},
  mounted: function mounted() {
    this.thisDates = this.$utils.DateUtils.getYYYYMM(new Date());
    this.thisDate = this.thisDates + '-01';
    this.kong = this.$utils.DateUtils.getWeekTimeStamp(this.thisDate);
    this.getSelectUserBanci();
  },
  methods: {
    setDate: function setDate(idx) {
      if (idx == 1) {
        this.dateidx--;
        this.thisDate = this.$utils.DateUtils.getonMonth(this.thisDate);
      } else {
        this.dateidx++;
        this.thisDate = this.$utils.DateUtils.gettheMonth(this.thisDate);
      }

      this.thisDates = this.$utils.DateUtils.getYYYYMM(this.thisDate);
      this.kong = this.$utils.DateUtils.getWeekTimeStamp(this.thisDate);

      if (this.kong == 0) {
        this.kong = 7;
      }

      this.getSelectUserBanci();
    },
    getSelectUserBanci: function getSelectUserBanci() {
      var _this = this;

      this.toneIdxs = 1;
      this.dateList = [];
      this.banci = [];
      this.$api.Attence.getSelectUserBanci({
        rq: this.thisDates,
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this.$utils.Tools.loadHide();

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.banci = data.banci.map(function (item) {
            if (item.bh != '') {
              if (_this.paibanitems == item.bh) {
                item.tone = _this.tone[_this.toneIdxs];
                item.idx = _this.character[_this.toneIdxs];
              } else {
                _this.paibanitems = item.bh;
                item.tone = _this.tone[_this.toneIdxs];
                item.idx = _this.character[_this.toneIdxs];
                _this.toneIdxs++;
              }
            } else {
              {
                item.tone = '#969696';
                item.idx = '';
                item.bh = '';
                item.mc = '未排班';
              }
            }

            return item;
          }); // this.banci.push()
          // this.banci.push({
          //   idx: this.character[0],
          //   tone: this.tone[0],
          //   bh: null,
          //   mc: '未排班'
          // })

          for (var index = 1; index < _this.kong; index++) {
            _this.dateList.push({
              idx: '',
              i: '',
              tone: '#1'
            });
          }

          data.paiban.map(function (item, idx) {
            var idxs = idx + 1;

            _this.banci.map(function (banci) {
              if (banci.bh == item) {
                _this.dateList.push({
                  idx: idxs,
                  i: idxs,
                  tone: banci.tone
                });
              }
            });
          });
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    }
  }
};